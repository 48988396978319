import React, { Component } from 'react';
import SignUp from '../../Pages/LoginPage/SignUp';

class SignUpRouter extends Component {
    render() {
        return (
            <div>
                <SignUp></SignUp>
            </div>
        );
    }
}

export default SignUpRouter;