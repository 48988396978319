import React, { Component } from 'react';

import './style/AnalysisBanner.scss'

class AnalysisBanner extends Component {
    render() {
        return (
            <div className="Analy_Container">
                <div className="TextBox">
                    <div className="Text">informaition</div>
                    <div className="Title">지입 정보</div>
                </div>
            </div>
        );
    }
}

export default AnalysisBanner;