export const data = [
  {
    number: "21",
    date: "2022.01.02",
    title: "한빛로지스 게시판 중 한빛 게시판입니다.",
    people: "한빛로지스",
    view: "192",
    comment: "3",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "20",
    date: "2022.01.02",
    title: "한빛로지스 제목이 들어가는 곳입니다.",
    people: "한빛로지스",
    view: "54",
    comment: "2",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "19",
    date: "2022.01.02",
    title: "한빛로지스 게시판 중 한빛 게시판입니다.",
    people: "화물트럭",
    view: "34",
    comment: "5",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "18",
    date: "2022.01.02",
    title: "게시판 내용이 들어갑니다.",
    people: "화물트럭",
    view: "23",
    comment: "6",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "17",
    date: "2022.01.02",
    title: "한빛로지스 제목이 들어가는 곳입니다.",
    people: "한빛로지스",
    view: "52",
    comment: "3",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "16",
    date: "2022.01.02",
    title: "한빛로지스 게시판 중 한빛 게시판입니다.",
    people: "한빛로지스",
    view: "79",
    comment: "7",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "15",
    date: "2022.01.02",
    title: "게시판 내용이 들어갑니다.",
    people: "화물트럭",
    view: "56",
    comment: "4",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "14",
    date: "2022.01.02",
    title: "한빛로지스 제목이 들어가는 곳입니다.",
    people: "한빛로지스",
    view: "63",
    comment: "2",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "13",
    date: "2022.01.02",
    title: "한빛로지스 게시판 중 한빛 게시판입니다.",
    people: "화물트럭",
    view: "87",
    comment: "1",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "12",
    date: "2022.01.02",
    title: "게시판 내용이 들어갑니다.",
    people: "화물트럭",
    view: "76",
    comment: "1",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "11",
    date: "2022.01.02",
    title: "한빛로지스 제목이 들어가는 곳입니다.",
    people: "한빛로지스",
    view: "43",
    comment: "3",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "10",
    date: "2022.01.02",
    title: "한빛로지스 게시판 중 한빛 게시판입니다.",
    people: "화물트럭",
    view: "52",
    comment: "4",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "9",
    date: "2022.01.02",
    title: "게시판 내용이 들어갑니다.",
    people: "화물트럭",
    view: "15",
    comment: "5",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "8",
    date: "2022.01.02",
    title: "한빛로지스 제목이 들어가는 곳입니다.",
    people: "한빛로지스",
    view: "87",
    comment: "2",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "7",
    date: "2022.01.02",
    title: "한빛로지스 게시판 중 한빛 게시판입니다.",
    people: "화물트럭",
    view: "98",
    comment: "2",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "6",
    date: "2022.01.02",
    title: "게시판 내용이 들어갑니다.",
    people: "화물트럭",
    view: "88",
    comment: "1",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "5",
    date: "2022.01.02",
    title: "한빛로지스 제목이 들어가는 곳입니다.",
    people: "한빛로지스",
    view: "55",
    comment: "1",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "4",
    date: "2022.01.02",
    title: "한빛로지스 게시판 중 한빛 게시판입니다.",
    people: "화물트럭",
    view: "33",
    comment: "1",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "3",
    date: "2022.01.02",
    title: "게시판 내용이 들어갑니다.",
    people: "화물트럭",
    view: "22",
    comment: "1",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "2",
    date: "2022.01.02",
    title: "한빛로지스 게시판 중 한빛 게시판입니다.",
    people: "화물트럭",
    view: "11",
    comment: "4",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
  {
    number: "1",
    date: "2022.01.02",
    title: "한빛로지스 제목이 들어가는 곳입니다.",
    people: "한빛로지스",
    view: "1",
    comment: "1",
    text:
      "안녕하세요. 한빛 게시판입니다. 이 곳에 내용이 들어갈 예정입니다. 들어갈 내용은 한빛 게시판에 대한 내용이고, 한빛 게시판에 대한 내용을 제한없이 적을 수 있습니다. 이상 한빛 게시판에 대한 설명이었습니다. 감사합니다.",
  },
];
